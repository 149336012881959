import { useTranslation } from 'next-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Components
import Slider from '../../../../new-components/Slider';
import AreaDevCard from '../../../../new-components/cards/AreaDevCard';

// Helpers
import { EntityTypes } from '../../../../new-lib/constants/common';
import { useRouter } from 'next/router';

const TopAreas = (props: any) => {
  const isMobile = useSelector((state: any) => state.app.isMobile);
  const { locale } = useRouter();

  const { t } = useTranslation(['home', 'common']);

  const getSlug = (slug: string) => {
    if (locale === 'en' && slug === 'north-coast---sahel') {
      return 'north-coast';
    }

    return slug;
  };

  const getSliderChildren = (items: any) => {
    return items.map((item: any, index: number) => {
      return (
        <AreaDevCard
          entity={item}
          entityType={EntityTypes.AREA}
          key={item.id}
          cardPosition={index}
          linkTo={`${locale === 'ar' ? '/ar' : ''}/area/${getSlug(item.slug)}`}
        />
      );
    });
  };

  const getGridedCards = () => {
    return (
      <GridedCardsContainer
        data-test="top-areas-grid"
        itemScope
        itemType="http://schema.org/ItemList"
      >
        {props.areas.slice(0, 8).map((item: any, index: number) => (
          <AreaDevCard
            entity={item}
            entityType={EntityTypes.AREA}
            key={item.id}
            cardPosition={index}
            linkTo={`${locale === 'ar' ? '/ar' : ''}/area/${getSlug(
              item.slug
            )}`}
          />
        ))}
      </GridedCardsContainer>
    );
  };

  const topAreasSliderProps = {
    ...props,
    slidesPerView: 'auto' as 'auto',
    spaceBetweenSlides: 8,
    slidesPerGroup: 1,
    children: getSliderChildren(props.areas),
    width: '150px',
    height: 'auto'
  };
  return (
    <SecondSectionSlidersWrapper>
      <TopAreasContainer data-test="top-areas-container">
        <div className="top-areas-header">
          <h2 className="headline-title">{t('Top Areas')}</h2>
          {!isMobile && (
            <span className="headline-subtitle">
              {props.areas.length} {t('results_available')}
            </span>
          )}
        </div>
        {isMobile ? <Slider {...topAreasSliderProps} /> : getGridedCards()}
      </TopAreasContainer>
    </SecondSectionSlidersWrapper>
  );
};

const SecondSectionSlidersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const TopAreasContainer = styled.div<any>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: auto;
  .top-areas-header {
    display: flex;
    flex-direction: column;
    h2 {
      color: ${props => props.theme.light.colors.secondaryBlue};
    }
    span {
      color: ${props => props.theme.light.colors.black};
      font-size: ${props => props.theme.font.sizes.l};
      font-family: materialLight;
      margin-top: 4px;
    }
  }
  @media screen and (max-width: ${props =>
      props.theme.breakPoints.tablet.max}) {
    .area-dev-container {
      background: #fff;
      min-height: auto;
      padding: 16px;
    }
    .area-dev-card {
      width: 100%;
    }
    .entity-name {
      font-size: ${props => props.theme.font.sizes.s};
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
    .available-properties {
      display: none;
    }
  }
`;

const GridedCardsContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  .area-dev-card {
    width: calc(25% - 16px);
    background: #ffff;
  }
`;

export default TopAreas;
