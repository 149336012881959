import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import Background from '../../../../public/assets/images/home-images/landing-background.webp';

// Components
import SearchBarContainer from '../search-bar/SearchBarContainer';
import ImageWrapper from '../../../../new-components/ImageWrapper';

const DesktopLanding: React.FC<any> = () => {
  const { t } = useTranslation('home');
  return (
    <DesktopLandingContainer itemScope itemType="https://schema.org/CreativeWork">
      <ImageWrapper
        src={Background}
        alt="hero background image"
        fill
        style={{
          objectFit: 'cover',
          zIndex: '-1',
          aspectRatio: 'unset'
        }}
        placeholder="blur"
        priority
        quality={50}
      />
      <div className="background-overlay"></div>
      <div className="header-content container-fluid">
        <div className="heading" itemProp="text">
          <h1>{t('landing_title')}</h1>
          <p>{t('landing_sub_header')}</p>
        </div>
        <SearchWrapper>
          <SearchBarContainer />
        </SearchWrapper>
      </div>
    </DesktopLandingContainer>
  );
};

const DesktopLandingContainer = styled.div`
  width: 100%;
  height: calc(80vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.35);
    z-index: 1;
  }
  .header-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 32px;
    margin-top: 5%;
    z-index: 2;
    .heading {
      display: flex;
      flex-direction: column;
      gap: 8px;
      color: ${props => props.theme.light.colors.white};
      h1 {
        font-size: 60px;
        font-family: materialBold;
      }
      p {
        font-size: ${props => props.theme.font.sizes.xl};
        font-family: materialLight;
      }
    }
  }
`;

const SearchWrapper = styled.div`
  width: 100%;
  z-index: 999;
`;

export default DesktopLanding;
