import OffersBannerContainer from '../../../offers/components/home-banner/offers/OffersBannerContainer';

interface CityScapeBannerPresentationProps {
  config: string;
}
const CityScapeBannerPresentation = ({
  config
}: CityScapeBannerPresentationProps) => {
  return config === 'on-has-offers' ? <OffersBannerContainer /> : <></>;
};

export default CityScapeBannerPresentation;
