import React, { ChangeEvent, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useTranslation } from 'next-i18next';
import DropdownInput from './DropdownInput';
import Button from '../../../../new-components/ui/Button';
import SearchBar from '../../../search/components/search-bar/SearchBar';

export interface FilterProps {
  name: string;
  options: any[];
  selectedValues: number[];
}
export interface FiltersProps {
  property_types: FilterProps;
  bedrooms: FilterProps;
  price: FilterProps;
}

interface ChildProps {
  filters: FiltersProps;
  handleSearch: () => void;
  handleSelect: (e: ChangeEvent<HTMLInputElement>, filterType: string) => void;
}
const SearchBarPresentation = ({
  filters,
  handleSelect,
  handleSearch
}: ChildProps) => {
  const { t } = useTranslation('common');
  const theme = useContext(ThemeContext);
  return (
    <Container itemScope itemType="http://schema.org/SearchAction">
      <div className="search-input">
        <SearchBar />
      </div>
      <DropdownInput
        filter={filters.property_types}
        handleSelect={handleSelect}
      />
      <DropdownInput filter={filters.bedrooms} handleSelect={handleSelect} />
      <DropdownInput filter={filters.price} handleSelect={handleSelect} />
      <Button
        backgroundColor={theme.light.colors.primaryBlue}
        border="none"
        color="white"
        fontSize={theme.font.sizes.m}
        onClick={handleSearch}
        text={t('search')}
        backgroundHover={theme.light.hoverColors.primaryBlue}
        containerClass="search-btn"
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 32px;
  background-color: #ffffffe5;
  border-radius: ${props => props.theme.borderRadius};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  .search-input {
    flex: 1;
    height: 55px;
    border: 1px solid #b0b0b0;
    border-radius: ${props => props.theme.borderRadius};

    .closed-input,
    .opened-input {
      outline: none;
      border: none;

      > svg {
        width: 20px;
        height: 20px;
      }
      .placeholder {
        color: ${props => props.theme.light.colors.darkGrey};
        font-size: ${props => props.theme.font.sizes.l};
      }

      input {
        padding: 8px 16px;
        font-family: materialMedium;
      }
    }
    .container-fluid {
      width: 100%;
      .search-dropdown {
        top: 60px;
      }
    }
  }
  .search-btn {
    height: 55px;

    button {
      width: 140px;
      height: 100%;
      font-family: materialBold;
    }
  }
`;

export default SearchBarPresentation;
