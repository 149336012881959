import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import router, { useRouter } from 'next/router';
import RightIcon from '../../../../../new-components/svg-icons/RightIcon';
import LeftIcon from '../../../../../new-components/svg-icons/LeftIcon';
import { logEvent } from '../../../../../new-lib/analytics';

const OffersBannerDesktop = (): JSX.Element => {
  const { locale } = useRouter();
  const { t } = useTranslation('common');

  const bannerClickHandler = () => {
    router.push('/cityscape');
    logEvent.click('offr_view_all', 'UnderSearchBar', 'offer');
  };

  return (
    <Container
      onClick={bannerClickHandler}
      onLoad={() => router.prefetch('/cityscape')}
      itemScope
      itemType="http://schema.org/OfferCatalog"
    >
      <div className="banner-left">
        <div className="limited-offers">
          {locale === 'en' ? (
            <>
              <span className={`limited-text ${locale}`}>{t('special')}</span>
              <span className={`offers-text ${locale}`}>{t('deals')}</span>{' '}
            </>
          ) : (
            <>
              <span className={`offers-text ${locale}`}>{t('deals')}</span>
              <span className={`limited-text ${locale}`}>{t('special')}</span>
            </>
          )}
        </div>

        <div className="banner-info">
          <h2 className="banner-title-brand">
            <span className="banner-title-offers">
              {locale === 'en' ? t('limited_time') : t('offers')}
            </span>{' '}
            {locale === 'en' ? t('offers') : t('limited_time')}
          </h2>
          <p className="banner-subtitle">{t('reserve_your_unit')}</p>
        </div>
      </div>

      <div className="banner-right">
        {locale === 'en' ? (
          <RightIcon stroke="white" />
        ) : (
          <LeftIcon stroke="white" />
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  color: white;
  background-image: url('/assets/images/banners/cityscape-desktop.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 16px 32px 16px 16px;
  overflow: none;
  cursor: pointer;

  .banner-left {
    display: flex;
  }

  .limited-offers {
    display: flex;
    flex-direction: column;
    font-size: 50px;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    margin-inline-end: 30px;
    width: 273px;
    height: 151px;
    border-radius: 8px;
    background: linear-gradient(
      180deg,
      #ff9108 0%,
      #f2c01e 50.52%,
      #ff9108 100%
    );

    .limited-text {
      font-family: materialLight;

      &.en {
        margin-bottom: -18px;
      }
    }

    .offers-text {
      font-family: materialBold;

      &.en {
        letter-spacing: 5px;
      }
    }
  }

  .banner-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .banner-title-brand {
    color: #ff9108;
    font-family: materialBold;
    font-size: 38px;
    margin-bottom: 8px;
  }

  .banner-title-offers {
    color: #ffffff;
    text-transform: capitalize;
  }

  .banner-action {
    height: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .banner-subtitle {
    font-family: materialLight;
    font-size: 16px;
    color: white;
  }

  .banner-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    font-weight: 700;
    gap: 16px;
    height: 50px;
  }
`;
export default OffersBannerDesktop;
