import styled from 'styled-components';
import backgroundMobile from '../../../../public/assets/images/sahel-map/sahel-banner-mobile.svg';
import background from '../../../../public/assets/images/sahel-map/sahel-banner.svg';
import backgroundAr from '../../../../public/assets/images/sahel-map/sahel-banner-ar.svg';
import backgroundMobileAr from '../../../../public/assets/images/sahel-map/sahel-banner-ar.svg';
import bannerText from '../../../../public/assets/images/sahel-map/sahel-banner-text.svg';
import bannerTextAr from '../../../../public/assets/images/sahel-map/sahel-banner-text-ar.svg';
import bannerTextMobile from '../../../../public/assets/images/sahel-map/sahel-banner-text-mobile.svg';
import bannerTextMobileAr from '../../../../public/assets/images/sahel-map/sahel-banner-text-mobile-ar.svg';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { getFirstPathSegment, logEvent } from '../../../../new-lib/analytics';
import ImageWrapper from '../../../../new-components/ImageWrapper';
import RightIcon from '../../../../new-components/svg-icons/RightIcon';
import LeftIcon from '../../../../new-components/svg-icons/LeftIcon';

const SahelBanner = () => {
  const { t } = useTranslation('sahel');

  const isMobile = useSelector((state: any) => state.app.isMobile);

  const router = useRouter();

  const handleBannerClick = () => {
    logEvent.click(
      'sahel_banner_click',
      getFirstPathSegment(router.asPath),
      'sahel_map'
    );
  };

  const getBannerText = () => {
    if (isMobile && router.locale === 'en') {
      return (
        <Image
          src={bannerTextMobile}
          alt="Banner text"
          priority
          style={{ width: '165px', height: '57px', objectFit: 'cover' }}
        />
      );
    }

    if (isMobile && router.locale === 'ar') {
      return (
        <Image
          src={bannerTextMobileAr}
          alt="Banner text"
          priority
          style={{ width: '165px', height: '70px', objectFit: 'cover' }}
        />
      );
    }

    if (!isMobile && router.locale === 'en') {
      return (
        <Image
          src={bannerText}
          alt="Banner text"
          priority
          style={{
            width: '334px',
            height: '84px',
            zIndex: 99,
            objectFit: 'cover'
          }}
        />
      );
    }

    if (!isMobile && router.locale === 'ar') {
      return (
        <Image
          src={bannerTextAr}
          alt="Banner text"
          priority
          style={{
            width: '334px',
            height: '84px',
            zIndex: 99,
            objectFit: 'cover'
          }}
        />
      );
    }
  };

  const getBannerBackground = () => {
    if (isMobile && router.locale === 'en') {
      return (
        <ImageWrapper
          src={backgroundMobile}
          alt="Sahel map banner"
          fill
          style={{ zIndex: '-1', borderRadius: '8px', objectFit: 'cover' }}
          priority
        />
      );
    }

    if (!isMobile && router.locale === 'en') {
      return (
        <ImageWrapper
          src={background}
          alt="Sahel map banner"
          fill
          style={{ zIndex: '-1', borderRadius: '8px', objectFit: 'cover' }}
          priority
        />
      );
    }

    if (isMobile && router.locale === 'ar') {
      return (
        <ImageWrapper
          src={backgroundMobileAr}
          alt="Sahel map banner"
          fill
          style={{ zIndex: '-1', borderRadius: '8px', objectFit: 'cover' }}
          priority
        />
      );
    }

    if (!isMobile && router.locale === 'ar') {
      return (
        <ImageWrapper
          src={backgroundAr}
          alt="Sahel map banner"
          fill
          style={{ zIndex: '-1', borderRadius: '8px', objectFit: 'cover' }}
          priority
        />
      );
    }
  };

  return (
    <Link href="/sahel-map" prefetch>
      <Container onClick={handleBannerClick}>
        {getBannerBackground()}

        <div className="header">
          {getBannerText()}

          {!isMobile && <ExploreButton>{t('explore_now')}</ExploreButton>}
        </div>

        {isMobile && (
          <div className="arrow-icon">
            {router.locale === 'en' ? (
              <RightIcon stroke="white" />
            ) : (
              <LeftIcon stroke="white" />
            )}
          </div>
        )}
      </Container>
    </Link>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  height: 235px;
  cursor: pointer;
  border-radius: ${props => props.theme.borderRadius};
  z-index: 1;

  .header {
    display: flex;
    flex-direction: column;
    margin-inline-start: 90px;
    gap: 16px;
    color: #fff;
  }

  @media screen and (max-width: ${props =>
      props.theme.breakPoints.mobile.max}) {
    display: flex;
    align-items: start;
    height: 120px;
    padding: 18px 8px 22px 29px;

    .header {
      display: flex;
      flex-direction: column;
      margin-inline-start: 0;
    }

    .arrow-icon {
      position: absolute;
      top: 42%;
      inset-inline-end: 8px;

      svg {
        stroke-width: 2px;
      }
    }
  }
`;

const ExploreButton = styled.button`
  width: 240px;
  height: 50px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: ${props => props.theme.borderRadius};
  background: #fff;
  color: #2c3138;
  font-family: materialBold;
  text-transform: capitalize;
`;

export default SahelBanner;
