import { ChangeEvent, useCallback, useContext, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import SearchBarPresentation from './SearchBarPresentation';
import {
  SearchContext,
  SearchContextInterface
} from '../../../search/context/SearchContext';
import { SearchFiltersTypes } from '../../../../reducers/search-filters/reducer';
import { logEvent } from '../../../../new-lib/analytics';

const SearchBarContainer = () => {
  const { filterOptions, searchFiltersState } = useContext(
    SearchContext
  ) as SearchContextInterface;
  const { t } = useTranslation('search');
  const router = useRouter();
  const getPropertyTypes = useCallback(() => {
    let propertyTypes: any = [];
    filterOptions.property_types.forEach((item: any) => {
      propertyTypes.push({
        value: item.value,
        name: item.options.displayedValue
      });
    });
    return propertyTypes;
  }, []);
  const getBedroomOptions = useCallback(() => {
    let options = [];
    for (let index = 1; index < 5; index++) {
      options.push({ value: index, name: `${index} ${t('bedrooms')}` });
    }
    options.push({ value: '5+', name: `5+ ${t('bedrooms')}` });
    return options;
  }, []);
  const getPriceOptions = useCallback(() => {
    const options = [
      {
        value: 1,
        name: `1${t('m')} ${t('to')} 4${t('m')}`
      },
      {
        value: 2,
        name: `4${t('m')} ${t('to')} 10${t('m')}`
      },
      {
        value: 3,
        name: `10${t('m')} ${t('to')} 15${t('m')}`
      },
      {
        value: 4,
        name: `15${t('m')} ${t('to')} 30${t('m')}`
      },
      {
        value: 5,
        name: `30${t('m')} ${t('to')} 50${t('m')}`
      },
      {
        value: 6,
        name: `50${t('m')} ${t('to')} 100${t('m')}`
      }
    ];
    return options;
  }, []);
  const mapPriceToQuery: any = {
    1: { min: 1000000, max: 4000000 },
    2: { min: 4000000, max: 10000000 },
    3: { min: 10000000, max: 15000000 },
    4: { min: 15000000, max: 30000000 },
    5: { min: 30000000, max: 50000000 },
    6: { min: 50000000, max: 100000000 }
  };

  const initialFilterOptions = {
    property_types: {
      name: 'property_types',
      options: getPropertyTypes(),
      selectedValues: []
    },
    bedrooms: {
      name: 'bedrooms',
      options: getBedroomOptions(),
      selectedValues: []
    },
    price: {
      name: 'price',
      options: getPriceOptions(),
      selectedValues: []
    }
  };
  const [filters, setFilters] = useState<any>(initialFilterOptions);

  const handleSelect = (
    e: ChangeEvent<HTMLInputElement>,
    filterType: string
  ) => {
    const value = e.target.value;
    const name = e.target.name;
    let selected = filters[filterType].selectedValues;

    if (e.target.checked) {
      selected.push({ value, name });
    } else {
      selected = selected.filter((item: any) => item.value != value);
    }
    setFilters({
      ...filters,
      [filterType]: { ...filters[filterType], selectedValues: selected }
    });
  };

  const mapSelectedToQuery = () => {
    let queryArray = [];

    if (filters['property_types'].selectedValues.length > 0) {
      const propertyTypes = `${SearchFiltersTypes.PROPERTY_TYPES}=${filters[
        'property_types'
      ].selectedValues
        .map((item: any) => item.value)
        .toString()}`;
      queryArray.push(propertyTypes);
    }
    if (filters['bedrooms'].selectedValues.length > 0) {
      const bedrooms = `${SearchFiltersTypes.BEDROOMS}=${filters[
        'bedrooms'
      ].selectedValues
        .map((item: any) => item.value)
        .toString()}`;
      queryArray.push(bedrooms);
    }
    if (filters['price'].selectedValues.length > 0) {
      const priceValues = filters['price'].selectedValues
        .map((item: any) => item.value)
        .sort();
      const minValue = mapPriceToQuery[priceValues[0]].min;
      const maxValue = mapPriceToQuery[priceValues[priceValues.length - 1]].max;
      const price = `${SearchFiltersTypes.MIN_PRICE}=${minValue}&${SearchFiltersTypes.MAX_PRICE}=${maxValue}`;
      queryArray.push(price);
    }

    if (searchFiltersState.searchFilters.areas.length > 0) {
      const areas = `${
        SearchFiltersTypes.AREAS
      }=${searchFiltersState.searchFilters.areas.toString()}`;
      queryArray.push(areas);
    }

    if (searchFiltersState.searchFilters.developers.length > 0) {
      const developers = `${
        SearchFiltersTypes.DEVELOPERS
      }=${searchFiltersState.searchFilters.developers.toString()}`;
      queryArray.push(developers);
    }

    return queryArray.join('&');
  };

  const handleSearch = () => {
    router.push({ pathname: '/search', query: mapSelectedToQuery() });
    logEvent.search('search_click', 'SearchBar', 'search', searchFiltersState);
  };

  return (
    <SearchBarPresentation
      filters={filters}
      handleSelect={handleSelect}
      handleSearch={handleSearch}
    />
  );
};

export default SearchBarContainer;
