import styled from 'styled-components';

// Components
import TopAreas from './components/top-areas/TopAreas';
import Landing from './components/landing-section';
import TopCompounds from './components/top-compounds/TopCompounds';
import RecomendedSection from './components/recommended-section/RecommendedSection';
import ServicesContainer from './components/services-cards/ServiceContainer';
import LaunchesBannerContainer from '../entity/launch/components/LaunchesBanner/LaunchesBannerContainer';
import { ConfigVariable } from '../../new-lib/types/common';
import CityScapeBannerPresentation from './components/cityscape';
import useQueryRedirect from '../../hooks/QueryRedirect';
import { useSelector } from 'react-redux';
import SahelBanner from '../sahel-mapbox/components/common/SahelBanner';

const HomePageContainer = (props: any) => {
  const cityscapeFlag =
    props.configVariables.find((obj: ConfigVariable) => {
      return obj['config_key'] === 'cityscape_offers';
    })?.config_value || false;

  useQueryRedirect({ query_params: props?.query });

  const sahelMapFlag =
    props.configVariables.find((obj: ConfigVariable) => {
      return obj['config_key'] === 'sahel_map_web';
    })?.config_value || false;

  const isMobile = useSelector((state: any) => state.app.isMobile);
  return (
    <HomeContainer>
      <Landing />

      {sahelMapFlag === 'true' && (
        <HomeWrapper className="container-fluid">
          <SahelBanner />
        </HomeWrapper>
      )}

      <div className="launches-home-banner container-fluid">
        <LaunchesBannerContainer launches={props.launches} />
      </div>

      <HomeWrapper className="container-fluid">
        <TopCompounds popularCompounds={props.popularCompounds} />
        {isMobile ? <ServicesContainer /> : <></>}

        {cityscapeFlag !== 'off' && (
          <CityScapeBannerPresentation config={cityscapeFlag} />
        )}
      </HomeWrapper>

      <HomeWrapper className="container-fluid">
        <TopAreas {...props} />
        {!isMobile ? <ServicesContainer /> : <></>}
        <RecomendedSection />
      </HomeWrapper>
    </HomeContainer>
  );
};

const HomeContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 64px;
  margin-bottom: 64px;

  @media screen and (max-width: ${props =>
      props.theme.breakPoints.tablet.max}) {
    gap: 32px;
    margin-bottom: 32px;
  }
`;

const HomeWrapper = styled.div<any>`
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 64px;
  @media screen and (max-width: ${props =>
      props.theme.breakPoints.tablet.max}) {
    gap: 32px;
  }
`;

export default HomePageContainer;
