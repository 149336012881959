import React, { ChangeEvent, useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import ThinArrowIcon from '../../../../new-components/svg-icons/ThinArrowIcon';
import { FilterProps } from './SearchBarPresentation';
import useOnClickOutside from '../../../../lib/Custom Hooks/ClickOutside';
import { logEvent } from '../../../../new-lib/analytics';
import {
  SearchContext,
  SearchContextInterface
} from '../../../search/context/SearchContext';

interface DropdownProps {
  filter: FilterProps;
  handleSelect: (e: ChangeEvent<HTMLInputElement>, filterType: string) => void;
}

const DropdownInput = ({ filter, handleSelect }: DropdownProps) => {
  const { t } = useTranslation('search');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { searchFiltersState } = useContext(
    SearchContext
  ) as SearchContextInterface;
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, dropdownRef, () => {
    setTimeout(() => setDropdownOpen(false), 250);
  });
  const getPlaceholder = () => {
    if (filter.selectedValues.length > 0) {
      let label: string = '';

      switch (filter.name) {
        case 'property_types':
          label = filter.selectedValues
            .map((value: any) => value.name)
            .join(', ');
          break;

        case 'bedrooms':
          label = `${filter.selectedValues
            .map((value: any) => value.value)
            .sort()} ${t(filter.name)}`;
          break;

        case 'price':
          const values = filter.selectedValues.map((value: any) => value.value);
          const minValue = filter.options[Math.min(...values) - 1];
          const maxValue = filter.options[Math.max(...values) - 1];

          label = `${minValue.name.split(t('to'))[0]} ${t('to')} ${
            maxValue.name.split(t('to'))[1]
          }`;
      }

      return (
        <span data-test="selected-search-filter" className="selected">
          {label}
        </span>
      );
    } else {
      return (
        <span data-test="unselected-search-filter"> {t(filter.name)}</span>
      );
    }
  };

  return (
    <Container
      data-test="search-filter-btn"
      ref={dropdownRef}
      onClick={() => {
        setDropdownOpen(!dropdownOpen);
      }}
    >
      {getPlaceholder()}

      <ThinArrowIcon />

      <Dropdown className={dropdownOpen ? '' : 'closed'}>
        <div className="options-wrapper" data-test="options-dropdown-menu">
          {filter.options.map((item: any) => {
            return (
              <label
                className="option"
                data-test="dropdown-menu-option"
                key={item.value}
              >
                <input
                  type="checkbox"
                  name={item.name}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    logEvent.search(
                      `filter_${filter.name}`,
                      'SearchBar',
                      'filter',
                      searchFiltersState
                    );

                    handleSelect(e, filter.name);
                  }}
                  value={item.value}
                />
                {item.name}
              </label>
            );
          })}
        </div>
      </Dropdown>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  background-color: white;
  border: 1px solid #b0b0b0;
  padding: 16px 10px;
  border-radius: ${props => props.theme.borderRadius};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: calc((100% - 172px) / 6);
  min-width: 160px;
  height: 55px;
  gap: 4px;
  span {
    color: ${props => props.theme.light.colors.darkGrey};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &.selected {
      color: ${props => props.theme.light.colors.black};
    }
  }

  svg {
    transform: rotate(270deg);
    min-width: 8px;
    height: 12px;
    path {
      stroke: ${props => props.theme.light.colors.grey};
    }
  }
`;

const Dropdown = styled.div`
  position: absolute;
  inset-inline-start: 0;
  max-height: 160px;
  top: 60px;
  background-color: white;
  border-radius: ${props => props.theme.borderRadius};
  padding: 8px;
  box-shadow: 0px 4px 10px 0px #00000026;
  width: 100%;
  overflow: auto;

  &.closed {
    display: none;
  }

  .options-wrapper {
    display: flex;
    flex-direction: column;
    height: max-content;
    .option {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      padding: 8px;
      font-size: ${props => props.theme.font.sizes.s};
      font-family: materialMedium;
      color: ${props => props.theme.light.colors.black};
      cursor: pointer;
      line-height: 18px;

      input[type='checkbox'] {
        pointer-events: none;
        cursor: pointer;
        -webkit-appearance: none;
        appearance: none;
        background-color: #fff;
        margin: 0;
        display: grid;
        place-content: center;
        font: inherit;
        width: 20px;
        height: 20px;
        border: 1px solid #d0d5dd;
        border-radius: 6px;
        color: #006694;

        &::before {
          content: '';
          width: 0.65em;
          height: 0.65em;
          transform: scale(0);
          transition: 0.1s transform ease-in-out;
          box-shadow: inset 15px 15px #006694;
          transform-origin: bottom left;
          clip-path: polygon(
            14% 44%,
            0 65%,
            50% 100%,
            100% 16%,
            80% 0%,
            43% 62%
          );
        }

        &:checked {
          border: 1px solid #006694;
          background-color: #e6f0f4;

          &::before {
            transform: scale(1);
          }
        }
      }
    }
  }
`;

export default DropdownInput;
