import styled from 'styled-components';
import router, { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import RightIcon from '../../../../../new-components/svg-icons/RightIcon';
import LeftIcon from '../../../../../new-components/svg-icons/LeftIcon';
import { logEvent } from '../../../../../new-lib/analytics';
import OffersBannerBackground from '../../../../../public/assets/images/banners/cityscape-offers-mobile.webp';
import Image from 'next/image';

const OffersBannerMobile = (): JSX.Element => {
  const { locale } = useRouter();
  const { t } = useTranslation('common');

  const bannerClickHandler = () => {
    router.push('/cityscape');
    logEvent.click('offr_view_all', 'UnderSearchBar', 'offer');
  };

  return (
    <Container
      onClick={bannerClickHandler}
      onLoad={() => router.prefetch('/cityscape')}
      itemScope
      itemType="http://schema.org/OfferCatalog" 
    >
      <Image
        src={OffersBannerBackground}
        alt="Offers banner"
        fill
        style={{ zIndex: '-1', borderRadius: '8px', objectFit: 'cover' }}
        priority
        placeholder="blur"
        itemProp='image'
      />

      <div className="banner-content">
        <h2 className="banner-title-brand">
          {locale === 'en' ? (
            <>
              <span className="banner-title-date">{`${t('limited_time')} 
          `}</span>
              {t('offers')}{' '}
            </>
          ) : (
            <>
              {t('offers')}{' '}
              <span className="banner-title-date">{`${t('limited_time')} 
          `}</span>{' '}
            </>
          )}
        </h2>
        <p className="banner-subtitle">{t('reserve_your_unit')}</p>
      </div>

      {locale === 'en' ? (
        <RightIcon stroke="white" />
      ) : (
        <LeftIcon stroke="white" />
      )}
    </Container>
  );
};

const Container = styled.div`
  height: 145px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 8px 16px;

  .banner-content {
    border-inline-start: 6px solid #ff9108;
    padding-inline-start: 16px;
    z-index: 2;

    .banner-title-brand {
      color: #ff9108;
      font-family: materialBold;
      margin-bottom: 10px;

      .banner-title-date {
        color: white;
        font-family: materialLight;
        font-size: 22px;
      }
    }
    .banner-subtitle {
      font-family: materialRegular;
      font-size: 14px;
      margin-bottom: 5px;
      color: white;
    }

    .banner-date {
      font-family: materialBold;
    }
  }
`;
export default OffersBannerMobile;
