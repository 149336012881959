import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import SearchIcon from '../../../../new-components/svg-icons/SearchIcon';
import { logEvent } from '../../../../new-lib/analytics';
import { useContext, useState } from 'react';
import {
  SearchContext,
  SearchContextInterface
} from '../../../search/context/SearchContext';
import Background from '../../../../public/assets/images/home-images/hero-bg-mobile.webp';
import Image from 'next/image';

const MobileLandingView = () => {
  const { t } = useTranslation('home');
  
  const { searching, setSearching, setShowUniversalSearch } = useContext(

    SearchContext
  ) as SearchContextInterface;
  return (
    <MobileViewContainer
      className="container-fluid"
      itemScope
      itemType="https://schema.org/CreativeWork"
    >
      <Image
        src={Background}
        alt="hero background image"
        fill
        style={{ objectFit: 'cover', overflow: 'hidden' }}
        priority
        placeholder="blur"
        quality={50}
        itemProp="image"
      />

      <h1 className="heading" itemProp="text">
        <span>{t('find_your')}</span>
        <span>{t('new_home')}</span>
      </h1>
      <MobileSearch
        onClick={() => {
          logEvent.click('search_click', 'SearchBar', 'search');
          setSearching(true);
          setTimeout(() => setShowUniversalSearch(true), 0);
        }}
      >
        <SearchIcon />
        {searching ? '' : <span>{t('search_by_entity')}</span>}
      </MobileSearch>
    </MobileViewContainer>
  );
};

const MobileViewContainer = styled.div`
  position: relative;
  width: 100%;
  height: 316px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  gap: 26px;
  h1 {
    color: ${props => props.theme.light.colors.white};
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 99;

    span:first-child {
      font-family: materialLight;
      font-size: 30px;
    }
    span:last-child {
      font-family: materialBold;
      font-size: 50px;
      line-height: 44px;
    }
  }
`;
const MobileSearch = styled.button`
  width: 100%;
  padding: 16px;
  border: none;
  border-radius: 8px;
  background-color: ${props => props.theme.light.colors.white};
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 8px;
  font-family: materialLight;
  font-size: ${props => props.theme.font.sizes.s};
  color: ${props => props.theme.light.colors.darkGrey};
  gap: 4px;
  height: 55px;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-shadow: 0px 12px 15px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  svg {
    width: 21px;
    height: 21px;
    path {
      fill: ${props => props.theme.light.colors.black};
      fill: ${props => props.theme.light.colors.darkGrey};
    }
  }
`;

export default MobileLandingView;
