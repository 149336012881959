import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import Logo from '../ui/Logo';
import Link from 'next/link';
import { logEvent } from '../../new-lib/analytics';
import { commifyNumber } from '../../helpers/common';
import { useSelector } from 'react-redux';

interface AreaDevCardProps {
  entity: any;
  entityType: string;
  cardPosition: number;
  linkTo: string;
}

const AreaDevCard = ({
  entity,
  entityType,
  cardPosition,
  linkTo
}: AreaDevCardProps) => {
  const { t } = useTranslation('common');
  const _entityType = entityType == 'developer' ? 'dev' : entityType;
  const cardDetails = {
    [`${_entityType}_id`]: entity.id,
    [`${_entityType}_name`]: entity.name,
    index: cardPosition
  };

  const handleClickEvent = () => {
    logEvent.click(
      `${_entityType}_click`,
      `all ${_entityType}s page`,
      entityType,
      { ...cardDetails }
    );
  };

  const isMobile = useSelector((state: any) => state.app.isMobile);

  return (
    <Link href={linkTo} prefetch={false} legacyBehavior>
      <a
        onClick={() => handleClickEvent()}
        className="area-dev-card"
        data-test="area-dev-card"
        itemProp="itemListElement" 
        itemScope 
        itemType="https://schema.org/ListItem"
      >
        <Container className="area-dev-container" itemProp="item">
          {!isMobile && (
            <div className="logo-container">
              <Logo logoPath={entity.image} />
            </div>
          )}

          <div className="entity-name headline-2">{entity.name}</div>

          <div className="compounds-count text-2">
            {entity.compounds} {t('compounds')}
          </div>

          <div className="available-properties text-2">
            {entity.properties} {t('properties_available')}
          </div>
          {entity.min_price && (
            <>
              <span className="text-2">{t('start_from')}</span>
              <div className="min-price headline-1">
                {commifyNumber(parseInt(entity.min_price))} {t('egp')}
              </div>
            </>
          )}
        </Container>
      </a>
    </Link>
  );
};

const Container = styled.div`
  cursor: pointer;
  width: 100%;

  min-height: 248px;
  height: 100%;
  border: 1px solid ${props => props.theme.light.borderColors.primary};
  border-radius: 10px;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${props => props.theme.light.colors.black};

  .logo-container {
    width: 100px;
    height: 100px;
    margin-bottom: 16px;
  }
  .entity-name,
  .compounds-count,
  .available-properties,
  .min-price {
    color: ${props => props.theme.light.colors.black};
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .entity-name {
    @media screen and (max-width: ${props =>
        props.theme.breakPoints.tablet.max}) {
      color: ${props => props.theme.light.colors.black};
    }
  }
  .compounds-count,
  .available-properties {
    text-transform: capitalize;
    color: #666;

    @media screen and (max-width: ${props =>
        props.theme.breakPoints.tablet.max}) {
      font-size: ${props => props.theme.font.sizes.xxs};
    }
  }
  span {
    color: ${props => props.theme.light.colors.grey};
  }
`;

export default AreaDevCard;
